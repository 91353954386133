import React, { useEffect, useState } from 'react';
import { withApollo } from 'react-apollo';
import VirtualIcon from 'static/images/virtual-tour.svg';
import FilterToggleIcon from 'static/icons/fiter-toggle.svg';
import BuilderCard from 'components/BuilderCard';
import { BUILDERS_QUERY } from 'graphqlQueries/buildersQuery';
import FilterItem from 'components/FilterItem';
import styles from './Builders.module.scss';

export const Builders = ({ client, heading }) => {
  const [builders, setBuilders] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [filter, setFilter] = useState({
    bathrooms: "all",
    bedrooms: "all",
    garage: "all",
    storeys: "all",
    specialOffer: "all"
  });
  useEffect(() => {
    (async () => {
      const result = await client.query({
        query: BUILDERS_QUERY,
      });
      let  buildersLists = result?.data?.builders?.edges;
      if(filter.specialOffer === "yes") {
        buildersLists = buildersLists?.filter((item) => item?.node?.builderFields?.status?.toLowerCase() === "special offer")
      }
      if(filter.bedrooms !== "all") {
        buildersLists = buildersLists?.filter((item) => item?.node?.builderFields?.bedrooms === filter.bedrooms)
      }
      if(filter.bathrooms !== "all") {
        buildersLists = buildersLists?.filter((item) => item?.node?.builderFields?.bathrooms === filter.bathrooms)
      }
      if(filter.garage !== "all") {
        buildersLists = buildersLists?.filter((item) => item?.node?.builderFields?.carSpaces === filter.garage)
      }
      if(filter.storeys !== "all") {
        buildersLists = buildersLists?.filter((item) => item?.node?.builderFields?.storeys === filter.storeys)
      }
      setBuilders(buildersLists);
    })();
   
  }, [client, filter]);

  return (
    <section className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.titleSection}>
          {heading && <h2 dangerouslySetInnerHTML={{ __html: heading }} />}
          <div className={styles.rightColumn}>
            <img className={styles.virtualIcon} src={VirtualIcon} alt="icon" />
            <p className={styles.virtualIconText}>
              Experience a 3600
              <span className={styles.degreeSymbol}>&#176;</span> <br />
              Virtual Tour
            </p>
          </div>
        </div>
        <div className={styles.filterWrapper}>
          <div className={styles.filterTopBar}>
            <div className={styles.filterTopBarLeft}>
              <h2>Refine your search</h2>
              <span onClick={() => setShowFilters(!showFilters)} className={styles.filterToggle}>
                <img src={FilterToggleIcon} alt="Filter toggle" />
              </span>
            </div>
            <div className={styles.reset}>Reset</div>
          </div>
          <h3>property type</h3>

          <div style={showFilters ? { display: 'flex' } : null } className={styles.filters}>
            <FilterItem filter={filter} setFilter={setFilter} filterName = "bedrooms" title="Bedrooms" data={['1', '2', '3', '4', '5+']} />
            <FilterItem filter={filter} setFilter={setFilter} filterName = "bathrooms" title="Bathrooms" data={['1', '2', '3+']} />
            <FilterItem filter={filter} setFilter={setFilter} filterName = "garage" title="Car Spaces" data={['1', '2']} />
            <FilterItem filter={filter} setFilter={setFilter} filterName = "storeys" title="Storeys" data={['1', '2']} />
            <FilterItem filter={filter} setFilter={setFilter} filterName = "specialOffer" title="Special Offers" data={[]} />
          </div>
        </div>
        <div className={styles.gridContainer}>
          {builders?.map((builder, index) => (
            <BuilderCard builder={builder?.node} key={`builder-${index}`} />
          ))}
          {builders?.length === 0 && <p>Builders Not Available</p>}
        </div>
      </div>
    </section>
  );
};

export default withApollo(Builders);
