import gql from 'graphql-tag';

export const BUILDERS_QUERY = gql`
  query BuilderQuery {
    builders(first: 100) {
      edges {
        node {
          id
          builderFields {
            logo {
              mediaItemUrl
              altText
            }
            status
            threeSixtyView
            bathrooms
            bedrooms
            carSpaces
            storeys
          }
          slug
          title
        }
      }
    }
  }
`;
